import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { Layout, PostCard, Pagination } from '../components/common'
import { MetaData } from '../components/common/meta'

/**
* Tag page (/tag/:slug)
*
* Loads all posts for the requested tag incl. pagination.
*
*/
const Tag = ({ data, location, pageContext }) => {
    const tag = data.ghostTag
    const posts = data.allGhostPost.edges

    return (
        <>
            <MetaData
                data={data}
                location={location}
                type="series"
            />
            <Layout>
                <section className="outer">
                    <div className="inner posts">
                        <div className="post-feed">

                            <section className="post-card post-card-large">
                                {tag.feature_image && 
                                    <figure className="article-image">
                                        <img
                                            srcSet={`${tag.feature_image} 300w,
                                                ${tag.feature_image} 600w,
                                                ${tag.feature_image} 1000w,
                                                ${tag.feature_image} 2000w`}
                                            sizes="(min-width: 1400px) 1400px, 92vw"
                                            src={tag.feature_image}
                                            alt={tag.title}
                                        />
                                    </figure>
                                }

                                <div className="post-card-content">
                                    <div className="post-card-content-link">
                                        <header className="post-card-header">
                                            <div className="post-card-primary-tag">Tagged</div>
                                            <h2 className="post-card-title">{tag.name}</h2>
                                        </header>

                                        {!!tag.description &&
                                            <div className="post-card-excerpt">
                                                <p>
                                                    {tag.description }
                                                </p>
                                            </div>
                                        }
                                    </div>
                                </div>{/* .post-card-content */}

                            </section>


                            {posts.map(({ node }, index) => (
                                // The tag below includes the markup for each post - components/common/PostCard.js
                                <PostCard key={node.id} post={node} index={index} home={false} />
                            ))}
    
                        </div>


                        <Pagination pageContext={pageContext} />

                    </div>
                </section>
            </Layout>
        </>
    )
}

Tag.propTypes = {
    data: PropTypes.shape({
        ghostTag: PropTypes.shape({
            name: PropTypes.string.isRequired,
            description: PropTypes.string,
        }),
        allGhostPost: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
}

export default Tag

export const pageQuery = graphql`
    query GhostTagQuery($slug: String!, $limit: Int!, $skip: Int!) {
        ghostTag(slug: { eq: $slug }) {
            ...GhostTagFields
        }
        allGhostPost(
            sort: { order: DESC, fields: [published_at] },
            filter: {tags: {elemMatch: {slug: {eq: $slug}}}},
            limit: $limit,
            skip: $skip
        ) {
            edges {
                node {
                ...GhostPostFields
                }
            }
        }
    }
`
